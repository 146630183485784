<!--
 * @Author: wankong
 * @Date: 2021-09-04 15:38:24
 * @LastEditTime: 2021-11-04 08:47:46
-->
<template>
  <div class="home">
    <template v-if="artinfo">
      <div v-title :data-title="title"></div>
      <header>
        <MHeader class="header" />
        <ArtTop
          v-if="artinfo"
          :auth="artinfo.author"
          :title="artinfo.title"
          :img="artinfo.image"
          :tags="artinfo.tags"
        />
      </header>
      <div class="main" :style="'background-image: url(' + backImg + ')'">
        <byte-md v-if="artinfo" :pdata="artinfo"></byte-md></div
    ></template>
    <BackTop />
  </div>
</template>

<script>
import MHeader from "@/components/common/Header";
import ArtTop from "@/components/article/ArtTop";
import BackTop from "@/components/common/BackTop";
import ByteMd from "../components/article/ByteMd.vue";

export default {
  components: {
    MHeader,
    BackTop,
    ArtTop,
    ByteMd,
  },
  name: "Article",

  data() {
    return {
      backImg: this.$store.state.config.web_bgimg,
      artinfo: null,
      title: "晚空写字的地方",
    };
  },

  created() {
    const aid = this.$route.params.id;
    this.GetMarkData(aid);
  },

  methods: {
    async GetMarkData(aid) {
      const { data: res } = await this.$http.get(`blog/${aid}`);
      this.artinfo = res.data;
      this.title =
        this.artinfo.title + "——" + this.$store.state.config.web_title;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  background: #b2b4b6;
}

.main {
  width: 100%;
  padding-top: 50px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@media screen and(max-width:768px) {
  .main {
    padding-top: 10px !important;
  }
}
</style>
