<template>
  <!-- 友情链接 -->
  <MMCard class="comments" :show-title="true" title="留个评论呗">
    <template slot="content">
      <div id="comment_box" class="form_box">
        <div class="text_box">
          <textarea
            name="content"
            v-model="formInfo.content"
            placeholder="说点什么吧..."
            required
          />
        </div>
        <div class="form_line">
          <div class="qq_box">
            <label for="userqq"><span style="color: red">*</span> QQ号:</label>
            <input
              maxlength="11"
              type="text"
              name="qq"
              placeholder="请输入您的QQ号"
              v-model="formInfo.qq"
            />
          </div>
          <div class="submit_box">
            <button type="submit" @click="submitComments()">提交评论</button>
          </div>
        </div>
      </div>
      <hr />
      <div class="comment-List">
        <ul>
          <li v-for="comment in comments" :key="comment.id">
            <div class="comment">
              <div class="avatar left">
                <img
                  :src="
                    'https://q.qlogo.cn/headimg_dl?dst_uin=' +
                    comment.userqq +
                    '&spec=100'
                  "
                  class="avatar"
                  width="100"
                  height="100"
                />
              </div>
              <div class="commenttext">
                <div class="info">
                  {{ comment.nickname }} • {{ comment.ctime | timeFormatter }}
                  <span
                    class="reback"
                    @click="Reback(comment.id, comment.nickname)"
                    >回复</span
                  >
                </div>
                <div class="commentmain">
                  {{ comment.content }}
                </div>
              </div>
            </div>
            <ul>
              <li v-for="chi in comment.children" :key="chi.id">
                <div class="comment">
                  <div class="avatar left">
                    <img
                      :src="
                        'https://q.qlogo.cn/headimg_dl?dst_uin=' +
                        chi.userqq +
                        '&spec=100'
                      "
                      class="avatar"
                      width="100"
                      height="100"
                    />
                  </div>
                  <div class="commenttext">
                    <div class="info">
                      {{ chi.nickname }} • {{ chi.ctime | timeFormatter }}
                      <span
                        class="reback"
                        @click="Reback(comment.id, chi.nickname)"
                        >回复</span
                      >
                    </div>
                    <div class="commentmain">
                      {{ chi.content }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </template>
  </MMCard>
</template>
<script>
import MMCard from "@/components/common/Card";

export default {
  components: {
    MMCard,
  },
  props: {
    artid: {
      type: Number,
      require: false,
      default: 0,
    },
  },
  name: "comments",
  data() {
    return {
      formInfo: {
        bid: this.artid,
        qq: "",
        content: "",
        fid: "",
      },
      comments: [],
    };
  },
  methods: {
    Reback(pid, nickname) {
      this.formInfo.content = "回复给 @" + nickname + ":";
      this.formInfo.fid = pid;
    },
    async submitComments() {
      if (!this.checkData()) {
        return alert("请输入正确的QQ号！");
      }
      if (this.formInfo.content != "") {
        const { data: res } = await this.$http.post("comments", this.formInfo);
        if (res.code == 200) {
          alert("回复成功，待管理员审核后显示。");
        } else {
          alert("评论失败！");
        }
        this.getComments();
      }
    },
    async getComments() {
      const { data: res } = await this.$http.get(`comments/${this.formInfo.bid}`);
      this.comments = res.data;
    },
    checkData() {
      let reg = /^[1-9][0-9]{4,9}$/gim;
      if (reg.test(this.formInfo.qq)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getComments();
  },
};
</script>
<style lang="less" scoped>
.comments {
  width: 100%;
  .form_box {
    width: 100%;
    .text_box {
      overflow: hidden;
      textarea {
        transition: none;
        max-height: 100%;
        width: 100%;
        box-sizing: border-box;
        min-height: 150px;
        resize: vertical;
        padding: 0.6rem;
        font-size: 0.9rem;
        color: #666;
        font-weight: 300;
        border: 1px solid #eaeaea;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
        font-family: Optima-Regular, Optima, PingFangSC-light, PingFangTC-light,
          "PingFang SC", Cambria, Cochin, Georgia, Times, "Times New Roman",
          serif;
        border-radius: 10px;
      }
      textarea:focus-visible {
        outline: none;
        border: 1px solid #000;
      }
    }
    .form_line {
      margin: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .qq_box {
        display: flex;
        font-size: 14px;
        color: #666;
        align-items: center;
        input {
          border-radius: 10px;
          margin-left: 1rem;
          box-sizing: border-box;
          border: none;
          background: none;
          font-size: 14px;
          color: #666;
          background: #fff;
          line-height: 1.4rem;
          padding: 10px 1rem;
          border: 1px solid #eaeaea;
          overflow: hidden;
        }
        input:focus-visible {
          outline: none;
        }
      }
      .submit_box {
        button {
          cursor: pointer;
          padding: 10px 1rem;
          font-size: 14px;
          border-radius: 10px;
          border: none;
          background-color: #1aad19;
          color: #fff;
        }
        button:hover {
          background: #179b16;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  .comment-List {
    width: 100;
    ul {
      list-style: none;
      li {
        ul {
          margin-left: 2rem;
        }
      }
      .comment {
        display: flex;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        position: relative;
        border-bottom: solid 1px #ddd;
        .avatar {
          width: 50px;
          height: 50px;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
        .commenttext {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 1rem;
          .info {
            color: #666;
            font-size: 12px;
            span {
              float: right;
              cursor: pointer;
            }
          }
          .commentmain {
            margin-top: 6px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and(max-width:768px) {
  .form_line {
    margin: 10px 0 !important;

    .qq_box {
      input {
        padding: 10px !important;
      }
    }
    .submit_box {
      button {
        padding: 10px !important;
      }
    }
  }
}
</style>
