const CODE_URL = "//cdnjs.cloudflare.com/ajax/libs/highlight.js/10.5.0/styles/";
const THEME_URL = "//wx.mmifx.com/markTheme/";

export default function initTheme() {
  return {
    viewerEffect({ file }) {
      const themes = file.frontmatter || {
        theme: "channing-cyan",
        highlight: "atom-one-dark",
      };
      const codeTheme =
        document.head.querySelector("#CODE_THEME") ||
        document.createElement("link");
      codeTheme.id = "CODE_THEME";
      codeTheme.rel = "stylesheet";
      codeTheme.href = `${CODE_URL}${themes.highlight}.min.css`;
      document.head.contains(codeTheme)
        ? ""
        : document.head.appendChild(codeTheme);
      const mdTheme =
        document.head.querySelector("#MD_THEME") ||
        document.createElement("link");
      mdTheme.id = "MD_THEME";
      mdTheme.rel = "stylesheet";
      mdTheme.href = `${THEME_URL}${themes.theme}.min.css`;
      document.head.contains(mdTheme) ? "" : document.head.appendChild(mdTheme);
    },
  };
}
