<template>
  <div class="content">
    <div class="left">
      <MMCard :showTitle="false" class="art_card" opacity="0.9">
        <template slot="content">
          <div class="title">
            <div class="cate">
              <i class="bi bi-justify-left"></i>
              <span :title="pdata.cate.desc" @click="GoCate(pdata.cate.id)"
                >{{ pdata.cate.catename }}
              </span>
            </div>
            <div class="views">
              <i class="bi bi-eye"></i>
              <span title="浏览次数">{{ pdata.views }} </span>
            </div>
          </div>

          <Viewer
            id="byteViewer"
            class="article"
            :value="pdata.content"
            placeholder="写点什么吧"
            :plugins="plugins"
          >
            <img :src="lazyImg" alt="" srcset="" />
          </Viewer>
          <hr />
          <div class="ctime">
            写于：{{ pdata.lasttime | timeFormatter }}&nbsp;&nbsp; •
            &nbsp;&nbsp;转载请标明出处
          </div>
        </template>
      </MMCard>
      <Comments :artid="pdata.id" />

      <CopyRight class="mobile-show" style="display: none" />
    </div>
    <div class="right">
      <NowQrcode />
      <CopyRight />
      <MMCard class="sticky" title="目录导航" opacity="0.8">
        <template slot="content">
          <div id="nav">目录加载中...</div>
        </template>
      </MMCard>
    </div>
  </div>
</template>

<script>
import "bytemd/dist/index.min.css";
import { Viewer } from "@bytemd/vue";
import breaks from "@bytemd/plugin-breaks";
//重写a标签 加上 _blank
import customHrefTarget from "../../common/bytemd/pugins/customHrefTarget";
// 初始化theme 加载theme.css
import initTheme from "../../common/bytemd/pugins/initTheme";
// 初始化cutsomToc
import customToc from "../../common/bytemd/pugins/customToc";
import highlightSsr from "@bytemd/plugin-highlight-ssr";
import gemoji from "@bytemd/plugin-gemoji";
import gfm from "@bytemd/plugin-gfm";
import frontmatter from "@bytemd/plugin-frontmatter";

import MMCard from "@/components/common/Card";
import NowQrcode from "@/components/common/NowQrCode";
import lazyImg from "@/assets/img/lazyload.gif";
import Comments from "@/components/article/Comments";
import CopyRight from "@/components/common/CopyRight";

const plugins = [
  gfm(),
  breaks(),
  frontmatter(),
  initTheme(),
  customHrefTarget(),
  highlightSsr(),
  gemoji(),
  customToc("#nav"),
];
export default {
  name: "ByteMd",
  components: { MMCard, NowQrcode, Comments, CopyRight, Viewer },
  data() {
    return {
      mdata: "",
      plugins,
      lazyImg,
    };
  },

  props: {
    pdata: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    GoCate(cateid) {
      this.$router.push({ path: `/`, query: { cateid: cateid } });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  justify-content: space-around;
  position: relative;
  padding-bottom: 100px;
  .title {
    display: flex;
    justify-content: space-between;
    .cate {
      span {
        cursor: pointer;
        padding-left: 0.5rem;
      }
      span:hover {
        color: #409eff;
      }
    }
  }
  .left {
    width: 70%;
    .byteViewer {
      height: 100%;
    }
    .ctime {
      margin-top: 10px;
      font-size: 14px;
      padding: 1rem;
      color: #555;
    }
  }
  .right {
    width: 20%;
    .sticky {
      position: sticky;
      top: 10px;
    }
  }
}

@media screen and (min-width: 1366px) {
  .content {
    width: 1366px;
  }
}

@media screen and(max-width:768px) {
  .content {
    width: 100%;
    .title {
      .views {
        span {
          font-size: small !important;
        }
      }
    }
  }
  .left {
    width: 98% !important;
  }
  .right {
    display: none;
  }
  .mobile-show {
    display: block !important;
  }
}
</style>
