<template>
  <div class="top" :style="'background-image: url(' + img + ')'">
    <div class="shadow">
      <div class="center">
        <div class="user">
          <img :src="auth.avatar" />
          <h2 class="nickname">{{ auth.nickname }}</h2>
        </div>
        <div class="motto">
          <p class="content web-font">《 {{ title }}》</p>
        </div>
      </div>
      <TagList :tags="tags" />
    </div>
  </div>
</template>

<script>
import TagList from "@/components/common/TagList";

export default {
  components: {
    TagList,
  },
  name: "ArtTop",
  props: {
    tags: {
      type: Array,
      require: false,
    },
    title: {
      type: String,
      require: false,
      default: "标题加载失败",
    },
    auth: {
      type: Object,
      require: false,
      default: () => {
        return {
          img: "https://www.mmifx.com/content/uploadfile/201907/thum-3c911563865159.jpg",
          name: "晚空",
          id: 1,
        };
      },
    },
    img: {
      type: String,
      require: false,
      default:
        "https://photos.smugmug.com/Wallpapers/i-7Tds9J5/0/9d189a52/X2/HDRshooter-super-ultra-wide-wallpaper-034-X2.jpg",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.top {
  width: 100%;
  height: 360px;
  text-align: center;
  background-size: 6000px;
  background-position: left top;
  padding-top: 80px;
  background-repeat: no-repeat;
  .shadow {
    width: 100%;
    padding-top: 10px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .center {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

    .user {
      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        filter: brightness(1) contrast(1.1) saturate(1.5);
        transition: all ease-in-out 1s;
      }

      img:hover {
        transform: rotate(360deg);
        filter: brightness(1.1) contrast(1.2) saturate(1.5);
      }
      .nickname {
        margin: 10px;
        line-height: 2rem;
        letter-spacing: 0.2rem;
        cursor: default;
        color: #daf3ef;
        user-select: none;
      }
    }
    .motto {
      margin-top: 20px;
      display: inline-block;
      .content {
        font-size: 25px;
        text-decoration: dashed;
        color: #fff;
      }
      padding-bottom: 2px;
      border-bottom: solid 1px #fff;
    }
  }
}

@media screen and(max-width:768px) {
  .top {
    height: 300px;
  }
  .user {
    img {
      width: 100px !important;
      height: 100px !important;
    }
    .nickname {
      font-size: 1.3rem;
    }
  }
  .motto {
    .content {
      font-size: 20px !important;
    }
  }
}
</style>
