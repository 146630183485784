/**
 * 使用rehype-urls 用到bytemd
 *  url:website 信息，包括host、port、hostname、path、href等等
 *  node:节点信息 .properties 会被渲染正attribute
 *
 * */

import urls from "rehype-urls";
export default function customHrefTarget() {
  return {
    rehype: (u) =>
      u.use(urls, (url, node) => {
        if (Boolean(node.properties.href) == true) {
          node.properties.target = "_blank";
        }
      }),
  };
}
