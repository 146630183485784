export default function customToc(toc = "#toc") {
  return {
    viewerEffect: ({ markdownBody }) => {
      const tocElement = document.querySelector(toc);
      const headingElements = [];
      for (let i = 0, index = 0; i < markdownBody.children.length; i++) {
        const _node = markdownBody.children[i];
        if (
          _node.tagName.length == 2 &&
          _node.tagName[0] === "H" &&
          _node.tagName != "HR"
        ) {
          const i = Number(_node.nodeName[1]);
          headingElements.push({
            id: index++,
            level: i,
            text: _node.innerText,
            offsetTop: _node.getBoundingClientRect().top,
            node: _node,
          });
        }
      }
      //   添加元素
      tocElement.innerHTML = "";
      const ulElement = document.createElement("ul");
      ulElement.classList.add("bytemd_toc");
      headingElements.map((item, index) => {
        const liElement = document.createElement("li");
        liElement.innerHTML = item.text;
        liElement.classList.add(`bytemd_toc--item`);
        liElement.classList.add(`bytemd_toc--level${item.level}`);
        liElement.classList.add(`bytemd_toc--${item.id}`);
        liElement.addEventListener("click", () => item.node.scrollIntoView());
        index === 0 ? liElement.classList.add(`bytemd_toc--active`) : void 0;
        ulElement.appendChild(liElement);
      });
      tocElement.appendChild(ulElement);
      window.addEventListener("scroll", () => {
        document.querySelector(".bytemd_toc--item") == null
          ? void 0
          : (() => {
              const currentScrollTop = window.scrollY;
              const currentElement = document.querySelector(
                ".bytemd_toc--active"
              );
              for (let index = 0; index < headingElements.length; index++) {
                const element = headingElements[index];
                if (currentScrollTop < element.offsetTop) {
                  //   当前元素 ：
                  if (currentElement) {
                    // 移除之前的active
                    currentElement.classList.remove("bytemd_toc--active");
                  }
                  let tempIndex = index > 0 ? index - 1 : 0;
                  document
                    .querySelector(
                      `.bytemd_toc--${headingElements[tempIndex].id}`
                    )
                    .classList.add("bytemd_toc--active");
                  break;
                }
              }
            })();
      });
    },
  };
}
